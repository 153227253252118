import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useApiClient} from "../api";
import {useUser} from "../api/UserContext";

function Login() {
    const {register, handleSubmit, formState: {errors}, setError} = useForm();
    const client = useApiClient();
    const navigate = useNavigate();
    const {user, refreshUser} = useUser();

    const onSubmit = async (data) => {
        try {
            let response = await client.login(data.code);
            await refreshUser();
            navigate('/');
        } catch (e) {
            setError('code', {type: 'custom', message: 'Neteisingas kodas'})
        }


    };

    return (
        <div className="page flex justify-center">
            <div className={'grid grid-cols-1 md:grid-cols-2 space-x-10 w-5/6 mt-5 md:mt-20'}>
                <div className={'space-y-5'}>
                    <h2 className={'text-dark text-lg font-bold'}>Mi Solutions</h2>
                    <h1 className={'text-dark text-6xl font-bold'}>Gaukite turinį iš geriausių!</h1>
                    <p className={'text-dark text-xl'}>
                        Džiugu, kad domitės mūsų kursais! Jei norite įsigyti mokymus spauskite mygtuka pirkti arba
                        suveskite individualų mokymų kodą.
                    </p>
                    <div className={'flex-col'}>
                    <div className={'flex flex-col md:flex-row gap-x-10 gap-y-5'}>
                        <Link className={'text-black text-center bg-green font-medium text-lg rounded-full px-16 py-2'}
                              to={`/Payment`}>Pirkti</Link>
                        <div className={'text-lg justify-center font-medium flex items-center'}>ARBA</div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={'flex rounded-lg shadow-sm'}>
                                <input
                                    placeholder={'Unikalus kodo numeris'}
                                    className={'text-black text-center w-full min-w-60 rounded-s-full px-5 py-2 text-lg font-medium border-t-[0.1rem] border-l-[0.1rem] border-b-[0.1rem] outline-none border-dark'}
                                    id="code"
                                    type="code"
                                    {...register('code', {})}
                                />
                                <button type="submit"
                                        className="w-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 border-t-[0.1rem] border-r-[0.1rem] border-b-[0.1rem] border-dark text-sm font-semibold rounded-e-full text-black bg-green">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"/>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                    {errors.code && <div className={'text-red-500 flex w-full'}>{errors.code.message}</div>}
                    </div>
                </div>
                <div>
                    <img className={'2xl:w-5/6 hidden md:block'} src={'/loginimg.png'}/>

                </div>
            </div>

        </div>
    );
}

export default Login;
