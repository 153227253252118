import {Link} from "react-router-dom";
import AdminMenu from "../../components/AdminMenu";

function Configuration() {
  return (
    <div className="page">
        <AdminMenu ActiveItemNo={3}></AdminMenu>
    </div>
  );
}

export default Configuration;
