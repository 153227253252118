import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import {SuperContext} from 'react-super-context';
import {apiClientContext} from './api';
import Login from "./pages/Login";
import Home from "./pages/Home";
import Watch from "./pages/Watch";
import App from "./App";
import LoggedInRoutes from "./components/LoggedInRoutes";
import LoggedOutRoutes from "./components/LoggedOutRoutes";
import {userContext} from "./api/UserContext";
import Payment from "./pages/Payment";
import TandC from "./pages/TandC";
import AcceptPayment from "./pages/AcceptPayment";
import CancelPayment from "./pages/CancelPayment";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Videos from "./pages/admin/Videos";
import Users from "./pages/admin/Users";
import Configuration from "./pages/admin/Configuration";
import UploadVideo from "./pages/admin/UploadVideo";


const router = createBrowserRouter([
    {
        element: <App/>,
        children: [
            {
                element: <LoggedOutRoutes/>,
                children: [
                    {
                        path: "/Payment",
                        element: <Payment/>,
                    },
                    {
                        path: "/Login",
                        element: <Login/>,
                    },
                ]
            },
            {
                element: <LoggedInRoutes/>,
                children: [
                    {
                        path: "/admin",
                        element: <Videos/>,
                    },
                    {
                        path: "/admin/videos",
                        element: <Videos/>,
                    },
                    {
                        path: "/admin/users",
                        element: <Users/>,
                    },
                    {
                        path: "/admin/configuration",
                        element: <Configuration/>,
                    },
                    {
                        path: "/admin/uploadVideo",
                        element: <UploadVideo/>,
                    },
                    {
                        path: "/",
                        element: <Home/>,
                    },
                    {
                        path: "/Watch",
                        element: <Watch/>,
                    }
                ]
            },
            {
                path: "/TandC",
                element: <TandC/>
            },
            {
                path: "/PrivacyPolicy",
                element: <PrivacyPolicy/>
            },
            {
                path: "/AcceptPayment",
                element: <AcceptPayment/>
            },
            {
                path: "/CancelPayment",
                element: <CancelPayment/>
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <SuperContext
            contexts={[
                apiClientContext,
                userContext
            ]}
        >
            <RouterProvider router={router}/>
        </SuperContext>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
