import React from "react";
import {useNavigate} from "react-router-dom";
import {useApiClient} from "../api";
import {useEffect, useState} from "react";
import VideoJS from "../components/VideoJS";

function Watch() {
    const searchParams = new URLSearchParams(document.location.search);
    const client = useApiClient();
    const [video, setVideo] = useState({});
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_API_BASE;
    const fetchVideo = async () => {
        let id = searchParams.get('videoId');
        let videoRes = await client.getVideo(id);
        setVideo(videoRes);
    }

    useEffect(() => {
        fetchVideo();
    }, []);

    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        fluid:true,
        sources: [{
            src: 'http://localhost:5165/videosStorage/1/filename.m3u8',
            type: 'application/x-mpegURL',
            withCredentials: true
        }],
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    return (
        <div className="page">
            <div id={'video-container'}>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
            </div>
            
    
            <br/>
            {video.name}
        </div>
    );
}

export default Watch;
