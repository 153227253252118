import React, {useEffect} from "react";

import {Outlet} from "react-router-dom";
import Header from "./components/Header";
import {useUser} from "./api/UserContext";

const App = () => {
    const {user, refreshUser, clearUser, userIsFetched} = useUser();

    useEffect(() =>{
        refreshUser();
    }, []);
    
    if (!userIsFetched){
        return "";
    }
    
    return <div className={'text-black h-dvh flex flex-col'}>
        <Header />
        <Outlet />
    </div>;
};

export default App;