import AdminMenu from "../../components/AdminMenu";

function UploadVideo() {
  return (
    <div className="page">
        <AdminMenu ActiveItemNo={1}></AdminMenu>
    </div>
  );
}

export default UploadVideo;
