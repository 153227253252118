export const createApiClient = (httpClient) => ({
  buy: (name, email, discountCode) => httpClient.current.post(`api/buy`, {name: name, email:email, discountCode:discountCode}),
  checkDiscount: (discountCode) => httpClient.current.post(`api/buy/CheckDiscount`, {discountCode: discountCode}),
  login: (code) => httpClient.current.post(`api/auth/Login`, {code: code}),
  logout: () => httpClient.current.post(`api/auth/Logout`),
  getAllVideos: () => httpClient.current.get(`api/video/GetAll`),
  getAvailableVideos: (limit) => httpClient.current.get(`api/video/GetAvailable`, {limit:limit}),
  getVideo: (id) => httpClient.current.get(`api/video/GetVideo`, {id:id}),
  getMyInfo: () => httpClient.current.get(`api/auth/MyInfo`),
  GetItem: () => httpClient.current.get(`api/Items/GetMain`),
  GetPagedUsers: (limit, skip, search) => httpClient.current.get(`api/Admin/GetPagedUsers`, {limit:limit, skip:skip, search:search}),
  cancelAccess: (purchaseId) => httpClient.current.post(`api/Admin/CancelAccess`, {purchaseId: purchaseId}),
  confirmPayment: (purchaseId) => httpClient.current.post(`api/Admin/ConfirmPurchase`, {purchaseId: purchaseId}),
});
