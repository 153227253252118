import React, {useEffect, useState, usePromise, Fragment } from "react";
import {useApiClient} from "../api";
import {Navigate, useNavigate} from "react-router-dom";
import {useUser} from "../api/UserContext";
import { Menu, Transition } from '@headlessui/react'

const Header = () => {
    const client = useApiClient();
    const navigate = useNavigate();
    const {user, clearUser} = useUser();
    
    const logout = async () => {
        try {
            await client.logout();
            await clearUser();
            
            navigate('/Login')
        } catch (e) {
            console.log(e)
        }
    }
    
    const headHome = () => {
        navigate('/')
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    
    return <header className={'header bg-green-light py-4 2xl:py-6'}>
        <div className={'flex justify-between'}>
            <div className={'mx-10'}>
                <img onClick={() => headHome()} className={'h-5 md:h-8'} src={'/logo.png'}/>
            </div>
            <Menu as="div" className="relative inline-block text-left">
                {user && <div>
                    <div className={'mx-5 md:mx-10'}>
                        <Menu.Button className={'flex cursor-pointer'}>{user.name}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 ml-1 p-0.5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
                            </svg>
                        </Menu.Button>
                    </div>
                </div>}

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-2 z-10 mt-2  w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">

                            <Menu.Item>
                                {({active}) => (
                                    <a
                                        onClick={() => logout()}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        Atsijungti
                                    </a>
                                )}
                            </Menu.Item>

                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    </header>;
};

export default Header;