import React, {useEffect} from "react";

import {Navigate, Outlet} from "react-router-dom";
import {useUser} from "../api/UserContext";

const LoggedInRoutes = () => {
    const {user} = useUser();
    return user ? <Outlet /> : <Navigate to="/Login" replace />;
};

export default LoggedInRoutes;