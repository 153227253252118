import React from "react";

const VideoCard = ({size, thumbnail, name, shortDescription}) => {
    return (<div className={"max-w-sm rounded overflow-hidden shadow-lg"}>
        <img className={"w-full"} src={thumbnail} alt="Video thubmnail"></img>
            <div className={"px-6 py-4"}>
                <div className={"font-bold text-xl mb-2"}>{name}</div>
                <p className={"text-base"}>
                    {shortDescription}
                </p>
            </div>
    </div>);
};

export default VideoCard;