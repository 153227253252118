import { useCallback, useEffect, useMemo, useRef } from 'react';

const baseUrl = process.env.REACT_APP_API_BASE;

const urlWithParams = (url, queryParams) =>
  queryParams ? `${url}?${new URLSearchParams(queryParams).toString()}` : url;

const useHttpClient = () => {
  const getOptions = useCallback(
    async (method, body, sendAsForm) => {
      const options = {
        method,
        mode: 'cors',
        credentials: 'include',  
        headers: {},
        body: sendAsForm ? (body) : body ? JSON.stringify(body) : undefined,
      };

      if (method === 'PATCH') {
        options.headers['Content-Type'] = 'application/json-patch+json';
      } else if (body && !sendAsForm) options.headers['Content-Type'] = 'application/json';

      return options;
    },
    [],
  );

  const request = useCallback(
    async(
      path,
      method,
      queryParams,
      body,
      sendAsForm,
      options,
    ) => {
      const url = urlWithParams(baseUrl + path, queryParams);
      const defaultOptions = await getOptions(method, body, sendAsForm);

      const mergedOptions = Object.assign(defaultOptions, options, {});

      const response = await fetch(url, mergedOptions);

      if (!response.ok) {
        throw response;
      }
      const contentType = response.headers.get('content-type');

      if (contentType === 'application/pdf') {
        return response;
      }

      if (response.status !== 204 && !contentType?.includes('application/json')) {
        throw new Error(`Unexpected response type ${contentType}`);
      }
      if (response.status === 204) {
        return {};
      }
      return await response.json();
    },
    [getOptions],
  );

  const client = useMemo(
    () => ({
      get: (path, queryParams, options) =>
        request(path, 'GET', queryParams, undefined, false, options),
      post: (
        path,
        body,
        queryParams,
        sendAsForm,
        options,
      ) => request(path, 'POST', queryParams, body, sendAsForm, options),
      put: (path, body, queryParams, options) =>
        request(path, 'PUT', queryParams, body, false, options),
      patch: (
        path,
        body,
        queryParams,
        options,
      ) => request(path, 'PATCH', queryParams, body, false, options),
      delete: (path, queryParams, options) =>
        request(path, 'DELETE', queryParams, undefined, false, options),
    }),
    [request],
  );

  // wrap the client in a ref so that dependencies don't have to refresh just because the token refreshed
  const clientRef = useRef(client);

  useEffect(() => {
    clientRef.current = client;
  }, [clientRef, client]);

  return clientRef;
};

export default useHttpClient;
