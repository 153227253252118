import {useForm} from 'react-hook-form';
import {useApiClient} from "../api";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function Payment() {
    const {register: paymentRegister, handleSubmit, formState: {errors}, setError} = useForm();
    const {register: discountRegister, handleSubmit: handleDiscountSubmit, formState: {errors: discountErrors}, setError: setDiscountError} = useForm();
    const client = useApiClient();
    const [buyingLoading, setBuyingLoading] = useState(false);
    const [discountCode, setDiscountCode] = useState({code: "", valid: false, discountedAmount: 0});
    const [item, setItem] = useState({price: 0, name: ""});
    const [finalSum, setFinalSum] = useState(0);
    
    const getItem = async () => {
        const res = await client.GetItem();
        setItem(res);
        setFinalSum(res.price);
    }
    
    useEffect(() => {
        getItem();
    }, []);

    const purchaseSubmit = async (data) => {
        if (buyingLoading) {
            return;
        }

        try {
            setBuyingLoading(true);
            let response = await client.buy(data.name, data.email, discountCode.code);
            window.location.replace(response.redirect);
            setBuyingLoading(false);
        } catch (e) {
            setBuyingLoading(false);
            console.error(e);
        }
    };
    
    const ApplyDiscount = (response, newDiscountCode) => {
        let priceCents = item.price;
        if (response.type === "Percentage"){
            const discountAmount = Math.round((item.price * response.amount / 100));
            priceCents = item.price - discountAmount;
            setDiscountCode({code: newDiscountCode, valid: true, discountedAmount: discountAmount});
        }else if(response.type === "Amount"){
            const discountAmount = response.amount;
            priceCents = (item.price - discountAmount);
            setDiscountCode({code: newDiscountCode, valid: true, discountedAmount: discountAmount});
        }else{
            setDiscountError("discountCode", {
                type: "manual",
                message: "Nuoldaios kodas negalioja",
            })
            setDiscountCode({code: "", valid: false, discountedAmount: 0});
        }
        setFinalSum(priceCents);
    }

    const onDiscountSubmit = async (data) => {
        if (buyingLoading) {
            return;
        }

        try {
            setBuyingLoading(true);
            let response = await client.checkDiscount(data.discountCode);
            ApplyDiscount(response, data.discountCode);
            setBuyingLoading(false);
        } catch (e) {
            setBuyingLoading(false);
            console.error(e);
        }
    };

    return (
        <div className="page container mx-auto flex flex-col flex-1">
            <div className={'grid grid-cols-1 md:grid-cols-2 flex-1'}>
                <div className={'m-10 md:m-0 md:pt-10 md:pr-10 md:pl-10 lg:pr-20 lg:pl-40'}>
                    <div className={'my-5'}>
                        <h2 className={'text-2xl font-semibold'}>Apmokėjimas</h2>
                        <hr className={'h-0.5 leading-4 bg-ls-light mt-1'}/>
                    </div>

                    <form onSubmit={handleSubmit(purchaseSubmit)}>
                        <div className={'my-2'}>
                            <input
                                className={'text-black rounded-lg text-lg w-full p-2 font-medium border-2 border-ls-light'}
                                placeholder={'El. paštas'}
                                id="email"
                                type="email"
                                {...paymentRegister('email', {
                                    required: 'Privalomas laukas',
                                    pattern: {value: /^\S+@\S+$/i, message: 'Neteisingas el. paštas'}
                                })}
                            />
                            {errors.email && <div className={'text-red-500'}>{errors.email.message}</div>}
                        </div>
                        <div className={'my-2'}>
                            <input
                                className={'text-black rounded-lg text-lg w-full p-2 mt-2 font-medium border-2 border-ls-light'}
                                placeholder={'Vardas'}
                                id="name"
                                type="text"
                                {...paymentRegister('name', {
                                    required: 'Privalomas laukas',
                                })}
                            />
                            {errors.name && <div className={'text-red-500'}>{errors.name.message}</div>}
                        </div>
                        <div className={'my-2'}>
                            <p className={'font-bold'}>Mokėjimo būdas:</p>
                            <input
                                className={'m-2'}
                                type="radio"
                                value={'paysera'}
                                id={'payment-paysera'}
                                {...paymentRegister('payment', {})}
                                checked={true}
                            />
                            <label htmlFor="payment-paysera">El. Bankininksytė (Paysera)</label>
                        </div>
                        <div className={'mt-10 md:mt-36'}>

                            <button disabled={buyingLoading}
                                    className={'text-white bg-green font-bold text-md rounded-2xl w-full px-16 py-3'}
                                    type="submit">
                                Mokėti {finalSum/100}€
                            </button>
                            <p className={'text-sm text-ls-light font-light text-center my-1'}>Pirkdami sutinkate
                                su <Link to={'/TandC'} className={'text-blue-500 underline'}>terminais ir sąlygomis</Link> bei <Link to={'/PrivacyPolicy'} className={'text-blue-500 underline'}>privatumo politika</Link></p>
                        </div>
                    </form>
                </div>
                <div className={'m-10 md:m-0 md:pt-10 md:pr-10 md:pl-10 lg:pr-40 lg:pl-20 md:border-l-2'}>
                    <div className={'my-5'}>
                        <h2 className={'text-2xl font-semibold'}>Užsakymas</h2>
                        <hr className={'h-0.5 leading-4 bg-ls-light mt-1'}/>
                    </div>
                    <div>
                        <span
                            className={'flex justify-between h-10'}><p>{item.name}</p><p>{item.price / 100}€</p></span>
                        <hr className={'h-0.5 leading-4 bg-ls-light mt-1'}/>
                    </div>
                    <div>
                        <form onSubmit={handleDiscountSubmit(onDiscountSubmit)}>
                            <div className={'py-4'}>
                                <div className={'flex'}>
                                    <input
                                        className={'text-black rounded-lg text-lg w-full p-2 font-medium border-2 border-ls-light'}
                                        placeholder={'Nuolaidos kodas'}
                                        id="discountCode"
                                        type="text"
                                        {...discountRegister('discountCode')}
                                    />
                                    <button disabled={buyingLoading}
                                            className={'text-white bg-ls-light rounded-2xl px-4 py-2 ml-2'}
                                            type="submit">
                                        Pritaikyti
                                    </button>
                                </div>
                                {discountErrors.discountCode &&
                                    <div className={'text-red-500'}>{discountErrors.discountCode.message}</div>}</div>

                        </form>
                        <hr className={'h-0.5 leading-4 bg-ls-light mt-1'}/>
                    </div>
                    {discountCode.valid && <div>
                        <div className={'flex my-4'}>
                            <div className={'flex-1'}>
                                Nuolaidos kodas: {discountCode.code}
                            </div>
                            <div>
                                -{discountCode.discountedAmount/100}€
                            </div>
                        </div>
                        <hr className={'h-0.5 leading-4 bg-ls-light mt-1'}/>
                    </div>}
                    <div>
                        <div className={'flex mt-4'}>
                            <div className={'flex-1'}>
                                <p className={'text-2xl'}>Iš viso:</p>
                                <p className={'text-ls-light text-sm'}>Įskaitant mokęsčius</p>
                            </div>
                            <div className={'text-2xl'}>{finalSum/100}€</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
