import { createSuperContext } from 'react-super-context';
import useHttpClient from './useHttpClient';
import { useMemo } from 'react';
import { createApiClient } from './ApiClient';

const [apiClientContext, useApiClient] = createSuperContext(() => {
  const httpClient = useHttpClient();

  return useMemo(() => createApiClient(httpClient), [httpClient]);
});

export { apiClientContext, useApiClient };
