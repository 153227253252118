import AdminMenu from "../../components/AdminMenu";
import React, {useEffect, useState} from "react";
import {useApiClient} from "../../api";
import VideoCard from "../../components/VideoCard";
import {useNavigate} from "react-router-dom";

function Videos() {
    const client = useApiClient();
    const [videos, setVideos] = useState([]);
    const navigate = useNavigate();

    const redirect = (id) =>{
        navigate('/Watch?videoId='+id)
    }
    const fetchVideos = async() =>{
        let videosRes = await client.getAllVideos();
        setVideos(videosRes);
    }
    
    useEffect(() => {
        fetchVideos();
    }, [])
    
  return (
      <div className="page">
          <AdminMenu ActiveItemNo={0}></AdminMenu>
          <div className={'flex'}>
              {videos &&
                  videos.map((x, i) => <li onClick={() => redirect(x.id)} key={i}>
                      <VideoCard name={x.name} shortDescription={x.description}></VideoCard></li>)
              }
          </div>
      </div>
  );
}

export default Videos;
