import {Link, Navigate, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useApiClient} from "../api";
import { redirect } from "react-router-dom";
import React, {useEffect, useState} from "react";
import VideoCard from "../components/VideoCard";
import Header from "../components/Header";

function Home() {
    const client = useApiClient();
    const [videos, setVideos] = useState([]);
    const navigate = useNavigate();

    const fetchVideos = async() =>{
        let videosRes = await client.getAvailableVideos(7);
        setVideos(videosRes);
    }

    const redirect = (id) =>{
        navigate('/Watch?videoId='+id)
    }
    
    useEffect(() => {
        fetchVideos();
    }, []);
  
  return (
      <div className="page">
          <div className={'flex'}>
              {videos &&
                  videos.slice(0, 2).map((x, i) => <li onClick={() => redirect(x.id)} key={i}>
                      <VideoCard name={x.name} shortDescription={x.description}></VideoCard></li>)
              }
          </div>

          <div className={'flex'}>
              {videos &&
                  videos.slice(2, 7).map((x, i) => <li onClick={() => redirect(x.id)} key={i}>
                      <VideoCard name={x.name} shortDescription={x.description}></VideoCard></li>)
              }
          </div>
      </div>
  );
}

export default Home;
