import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useApiClient} from "../api";
import {useUser} from "../api/UserContext";

function CancelPayment() {
    return (
        <div className="page flex justify-center">
            Apmokejimas nesekmingas
        </div>
    );
}

export default CancelPayment;
