import {useNavigate} from "react-router-dom";
import AdminMenu from "../../components/AdminMenu";
import {useApiClient} from "../../api";
import {useEffect, useState} from "react";
import LsButton from "../../components/LsButton";

function Users() {
    const client = useApiClient();
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSearchTerm, setCurrentSearchTerm] = useState("");
    const navigate = useNavigate();
    const pageSize = 20;

    const redirect = (id) => {
        navigate('/Watch?videoId=' + id)
    }

    const fetchUsers = async () => {
        let userResult = await client.GetPagedUsers(pageSize, pageSize * (currentPage - 1), currentSearchTerm);
        setUsers(userResult);
    }

    useEffect(() => {
        fetchUsers();
    }, [currentPage])
    

    useEffect(() => {
        if (currentPage === 1){
            fetchUsers()
        }else {
            setCurrentPage(1);
        }
    }, [currentSearchTerm])
    
    const cancelAccess = async (purchaseId) => {
        await client.cancelAccess(purchaseId);
        fetchUsers();
    }

    const confirmPayment = async (purchaseId) => {
        await client.confirmPayment(purchaseId);
        fetchUsers();
    }
    
    const nextPage = () => {
        setCurrentPage(x => x + 1);
    }

    const previousPage = () => {
        if (currentPage === 1){
            return;
        }
        setCurrentPage(x => x - 1);
    }
    
    const searchChange = (newValue) => {
        setCurrentSearchTerm(newValue);
    }
// need debounced input
    return (
        <div className="page">
            <AdminMenu ActiveItemNo={2}></AdminMenu>
            <div>
                <LsButton onClick={previousPage}>Previous Page</LsButton>
                <LsButton onClick={nextPage}>Next Page</LsButton>
                <div>Page: {currentPage}</div>
                <div>Search: <input type={"text"} onChange={e => searchChange(e.target.value)}/></div>
            </div>
            <div class="flex flex-col m-4">
                <div class="overflow-x-auto">
                    <div class="inline-block min-w-full"><div class="overflow-hidden">
                        <table class="min-w-full text-left text-sm font-light">
                                <thead class="border-b font-medium dark:border-neutral-500">
                                <tr>
                                    <th scope="col" className="px-2 py-4">Id</th>
                                    <th scope="col" className="px-2 py-4">Email</th>
                                    <th scope="col" className="px-2 py-4">Name</th>
                                    <th scope="col" className="px-2 py-4">Status</th>
                                    <th scope="col" className="px-2 py-4">Purchase Code</th>
                                    <th scope="col" className="px-2 py-4">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {users && users.map(user => {
                                    return <tr className="border-b dark:border-neutral-500">
                                        <td className="whitespace-nowrap px-2 py-4 font-medium">{user.id}</td>
                                        <td className="whitespace-nowrap px-2 py-4">{user.email}</td>
                                        <td className="whitespace-nowrap px-2 py-4">{user.name}</td>
                                        <td className="whitespace-nowrap px-2 py-4">{user.purchase.paymentConfirmed ? "Confirmed" : "Pending"}</td>
                                        <td className="whitespace-nowrap px-2 py-4">{user.purchase.userCode}</td>
                                        <td className="whitespace-nowrap px-2 py-4">{user.purchase.paymentConfirmed ?
                                            <LsButton onClick={() => cancelAccess(user.purchase.id)}>Cancel
                                                Access</LsButton> :
                                            <LsButton onClick={() => confirmPayment(user.purchase.id)}>Confirm
                                                Payment</LsButton>}</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Users;
