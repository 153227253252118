import React from "react";
import {Link} from "react-router-dom";

const AdminMenu = ({ActiveItemNo}) => {
    const itemsArr = [
        {Name: "Videos", Url: "/admin/videos"},
        {Name: "Upload a Video", Url: "/admin/uploadVideo"},
        {Name: "Users", Url: "/admin/users"},
        {Name: "Configuration", Url: "/admin/configuration"}
    ];
   
    return <div className={"grid text-center grid-cols-1 lg:grid-cols-4 p-5 lg:px-10 xl:px-40 gap-1"}>
        {itemsArr.map((item, i) => {
            const className = ActiveItemNo === i ? "px-16 py-3 border-green border-b-2 bg-green-light" : "px-16 py-3 hover:bg-green-light";
            return <Link to={item.Url}><div className={className}>{item.Name}</div></Link>;
        })}
    </div>;
};

export default AdminMenu;