import React from "react";

import {Navigate, Outlet} from "react-router-dom";
import {useUser} from "../api/UserContext";

const LoggedOutRoutes = () => {
    const {user} = useUser();
    return !user ? <Outlet /> : <Navigate to="/" replace />;
};

export default LoggedOutRoutes;