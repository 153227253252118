import React, {useEffect, useState} from "react";

function PrivacyPolicy() {
  return (
      <div className="page">
      </div>
  );
}

export default PrivacyPolicy;
