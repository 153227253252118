import { createSuperContext } from 'react-super-context';
import {useApiClient} from "../api";
import {useCallback, useState} from 'react';

const [userContext, useUser] = createSuperContext(() => {
  const [user, setUser] = useState(null);
  const [userIsFetched, setUserIsFetched] = useState(false);
  const client = useApiClient();

  const refreshUser = useCallback(async () => {
    try {
      let res = await client.getMyInfo();
      setUser(res);
      setUserIsFetched(true);
    }catch (e) {
      setUserIsFetched(true);
    }
  }, [setUser]);

  const clearUser = useCallback(async () => {
    setUser(null);
    setUserIsFetched(true);
  }, [setUser]);

  return { user, refreshUser, clearUser, userIsFetched };
});

export { userContext, useUser };
